import './App.css';
import { Container, Row, Col, Nav, NavbarToggler, Navbar, NavbarBrand, Collapse, NavItem, NavLink, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import NavBar from './components/navbar';
import { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './pages/home';
import Staking from './pages/staking';
import ethIcon from './assets/ethereum.webp';
import bscIcon from './assets/binance-coin-logo.webp';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Router>
      <Navbar color="dark" dark expand="md" className="p-2 bg-color-blue">
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar className='justify-content-center'>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/"><img src={bscIcon} /> BSC LP Staking Platform</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://staking.cryptocart.cc"><img src={ethIcon} /> ETH Staking Platform</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <Container className="mb-3 mb-lg-5">

        <Row id="cc-header" className="mb-3 py-3 mb-md-5 py-md-5 py-lg-6 mb-lg-6 align-items-center">
          <div className="col-5 col-md-4">
            <img src={"./img/logo.png"} className="w-75 h-auto" alt="CryptoCart Staking" />
          </div>
          <div className="col-7 col-md-8 d-flex justify-content-end">
            <a href="https://cryptocart-cc.medium.com/how-to-provide-liquidity-to-the-ccv2-bsc-pool-605fa8cdf2a9" className="buy-cta" target="_blank" rel="noreferrer">STAKING INSTRUCTIONS</a>
            <a href="https://pancakeswap.finance/add/BNB/0x612e1726435fe38dd49a0b35b4065b56f49c8f11" className="buy-cta buy-cta--alt" target="_blank" rel="noreferrer">ADD BSC LIQUIDITY</a>
          </div>
        </Row>
        <Row>
          <Col>
            <Switch>
              <Route path="/">
                <Staking />
              </Route>
            </Switch>
          </Col>
        </Row>
      </Container>
      <div id="cc-footer">
        <Container className="py-5 p-lg-5 text-center">
          <Row>
            <Col md="3" className="cc-footer-logo">
              <a href="https://giftcards.cryptocart.cc/" target="_blank" rel="noreferrer"><img src={"./img/footer-logo-gc.png"} className="cc-logo h-auto px-5 px-md-2 px-lg-3" alt="CryptoCart Giftcards" /></a>
            </Col>
            <Col md="3" className="cc-footer-logo">
              <a href="https://marketplace.cryptocart.cc/" target="_blank" rel="noreferrer"><img src={"./img/footer-logo-market.png"} className="cc-logo h-auto px-5 px-md-2 px-lg-3" alt="CryptoCart Marketplace" /></a>
            </Col>
            <Col md="3" className="cc-footer-logo">
              <a href="https://staking.cryptocart.cc/"><img src={"./img/footer-logo-staking.png"} className="cc-logo h-auto px-5 px-md-2 px-lg-3" alt="CryptoCart Staking" /></a>
            </Col>
            <Col md="3" className="cc-footer-logo">
              <a href="https://multibridge.cryptocart.cc/" target="_blank" rel="noreferrer"><img src={"./img/footer-logo-bridge.png"} className="cc-logo h-auto px-5 px-md-2 px-lg-3" alt="CryptoCart Multi-Bridge" /></a>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="cc-footermenu">
        <Container className="py-5 p-lg-5 p-lg-5">
          <Row className="py-md-5 p-lg-5">
            <Col md="3">
              <a href="https://cryptocart.cc/" target="_blank" rel="noreferrer"><img src={"./img/footer-logo.png"} className="footer-logo h-auto mb-5" alt="CryptoCart" /></a>
              <p className="mb-0 small">&copy; Copyright 2021 Cryptocart.</p>
              <p className="small">All rights reserved</p>
            </Col>
            <Col md="3">
              <ul>
                <li><strong>Here to help</strong></li>
                <li><a href="mailto:contact@cryptocart.cc">contact@cryptocart.cc</a></li>
              </ul>
              <ul className="mb-3 mb-md-0">
                <li><strong>Policy</strong></li>
                <li><a href="https://cryptocart.cc/privacy-policy/" target="_blank" rel="noreferrer">Privacy & cookies policy</a></li>
                <li><a href="https://cryptocart.cc/terms-of-use/" target="_blank" rel="noreferrer">General terms & conditions</a></li>
                <li><a href="https://cryptocart.cc/terms-of-service/" target="_blank" rel="noreferrer">Product terms & conditions</a></li>
              </ul>
            </Col>
            <Col md="3">
              <ul>
                <li><strong>Join Community</strong></li>
                <li><a href="https://twitter.com/cryptocartcc" target="_blank" rel="noreferrer"><img src="./img/ft-twitter.svg" alt="Twitter" /> @CryptoCartCC</a></li>
                <li><a href="https://t.me/cryptocartcc" target="_blank" rel="noreferrer"><img src="./img/ft-telegram.svg" alt="Telegram" /> CryptoCart Official</a></li>
              </ul>
              <ul>
                <li><strong>Explore CC Token</strong></li>
                <li><a href="https://www.coingecko.com/en/coins/cryptocart" target="_blank" rel="noreferrer"><img src="./img/ft-coingecko.svg" alt="Coin Gecko" /> Coin Gecko</a></li>
                <li><a href="https://www.dextools.io/app/uniswap/pair-explorer/0xf4d4e16c9a93d88990e0b98820c363369ca91c01" target="_blank" rel="noreferrer"><img src="./img/ft-dextools.svg" alt="Dextools" /> Dextools</a></li>
              </ul>
            </Col>
            <Col md="3">
              <ul>
                <li><strong>Buy CC Token</strong></li>
                <li><a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x9e547061a345015869d26c7b6ee4ab5b63424441" target="_blank" rel="noreferrer" className="cc-ft ft-uniswap"><img src="./img/ft-uniswap.svg" alt="Uniswap" /> UNISWAP</a></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </Router>
  );
}

export default App;
