import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, CardHeader, 
  CardFooter, CardBody, Input, Spinner } from 'reactstrap';
import Web3 from "web3";
import { ethers, BigNumber } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { poolAddress, tokenAddress, poolAbi, tokenAbi, lpTokenAbi } from "../../web3/config";

const Staking = (props) => {
  const supportedChains = [56];
  let updatingUi = false;
  const blockUpdateFrequency = 10;
  let lastBlock = 0;

  const [chainId, setChainId] = useState(0);
  const [provider, setProvider] = useState({});
  const [web3Provider, setWeb3Provider] = useState({});
  const [address, setAddress] = useState();
  const [toggleState, setToggleState] = useState(false);
  const [poolContract, setPoolContract] = useState({});
  const [tokenContract, setTokenContract] = useState({});
  const [hasApproval, setHasApproval] = useState(false);
  const [totalStaked, setTotalStaked] = useState(0);
  const [myStake, setMyStake] = useState(0);
  const [amountToStake, setAmountToStake] = useState(0);
  const [amountToWithdraw, setAmountToWithdraw] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [pendingApproval, setPendingApproval] = useState(false);
  const [pendingStake, setPendingStake] = useState(false);
  const [pendingWithdraw, setPendingWithdraw] = useState(false);
  const [pendingRewardClaim, setPendingRewardClaim] = useState(false);
  const [rewardEarned, setRewardEarned] = useState(0);
  const [totalRewardsPaid, setTotalRewardsPaid] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [isChainSupported, setIsChainSupported] = useState(false);
  const [myAPR, setMyAPR] = useState(0);
  const [currentBlock, setCurrentBlock] = useState(0);
  

  const MAX_APPROVAL = "115792089237316195423570985008687907853269984665640564039457584007913129639935";

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: "5efec6289805452dae35576f844e7afa", // required
        rpc: {
          1: "https://mainnet.infura.io/v3/",
          56: "https://bsc-dataseed.binance.org/",
          97: "https://data-seed-prebsc-1-s1.binance.org:8545/"
        }
      }
    }
  };

  const web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions, // required
    theme: {
      background: "rgba(2,11,14, 0.75)",
      main: "rgba(199, 199, 199, 1)",
      secondary: "rgb(136, 136, 136)",
      border: "rgba(195, 195, 195, 0.14)",
      hover: "rgba(2,11,14, 0.8)"
    }
  });

  useEffect(() => {
    console.log("Triggering useEffect");
    if (web3Modal.cachedProvider && !address) {
      console.log("Cached provider found");
      connectWallet();
    }

    if (tokenContract.address && poolContract.address && isChainSupported) {
      console.log("Token contract updated to ", tokenContract);
      console.log("Current block ", currentBlock);
      setup();
    }
  }, [address, tokenContract, poolContract, totalStaked, tokenBalance, chainId, isChainSupported, hasApproval, myAPR]);

  useEffect(() => {
    updateUI();
  }, [currentBlock]);

  function updateBlock(blockNumber) {
    if (lastBlock < (blockNumber - blockUpdateFrequency)) {
      setCurrentBlock(blockNumber);
      console.log("Refreshing UI, currentBlock ", blockNumber);
    }

  }

  async function connectWallet() {
    const web3Provider = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(web3Provider);
    const network = await provider.getNetwork();
    const chainId = network.chainId.toString();
    console.log('Connected to chainId', network.chainId);
    setIsChainSupported(supportedChains.includes(network.chainId));
    console.log("Connected to chain ", chainId);
    console.log("Pool info ", poolAddress);

    setChainId(network.chainId);
    setProvider(provider);
    const w3 = new Web3(web3Provider);
    setWeb3Provider(w3);
    const accounts = await w3.eth.getAccounts();
    const address = accounts[0];
    setAddress(address);
    setupContracts(network.chainId, provider);
    setupListeners(web3Provider, provider);
  }

  function setupContracts(chainId, provider) {

    console.log('Setting up contracts for chain', chainId);

    if (supportedChains.includes(chainId)) {
      setPoolContract(new ethers.Contract(poolAddress[chainId], poolAbi, provider));
      setTokenContract(new ethers.Contract(tokenAddress[chainId], lpTokenAbi, provider));

      console.log('Setting token contract to', tokenAddress[chainId], lpTokenAbi);
    } else {
      console.log("Unsupported chain %s", chainId);
    }
  }

  function setupListeners(provider, ethersProvider) {
    console.log("Setting up listeners");
    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts) => {
      setAddress(accounts[0]);
    });

    // Subscribe to chainId change
    provider.on("chainChanged", (chainId) => {
      const chain = BigNumber.from(chainId).toString();
      console.log("chainChanged ", chain);

      if (supportedChains.includes(chainId)) {
        setIsChainSupported(supportedChains.includes(chainId));
        connectWallet();
      } else {
        setIsChainSupported(false);
        setTotalStaked(0);
        setTokenBalance(0);
        setMyStake(0);
        setRewardEarned(0);
        setTotalRewardsPaid(0);
        setHasApproval(false);
      }
    });

    ethersProvider.on("block", updateBlock);
  }

  async function disconnectWallet() {
    console.log(web3Provider.currentProvider.stop);
    if (web3Provider.currentProvider.close) {
      console.log("Disconnecting wallet");
      web3Provider.currentProvider.close();
      await web3Modal.clearCachedProvider();
      setChainId(0);
    }
  }

  async function setup() {
    console.log("Initial setup");
    await checkAllowance();
    await totalSupply();
    await balance();
    await myStakedAmount();
    await myRewards();
    await getTotalRewardsPaid();
    await setAPR();
    setIsChainSupported(supportedChains.includes(chainId));
    setToggleState(!toggleState);
  }

  async function updateUI() {

    // Check to see if the UI is already updating so it doesn't cause a blocking instance with constantly updating
    if (updatingUi) return;

    updatingUi = true;

    try {
      await totalSupply();
      await balance();
      await myRewards();
      await getTotalRewardsPaid();
      await setAPR();
    } catch { }

    updatingUi = false;
  }

  async function setAPR() {
    const rewardRate = await poolContract.rewardRate();
    console.log(rewardRate.toString() / 1e18, 'Total reward rate divided');
    console.log(rewardRate.toString(), 'Total reward rate')
    const annualRewardAmount = (rewardRate.toString() / 1e18) * 60 * 60 * 24 * 365;
    //const apr = totalStaked > 0 ? annualRewardAmount / 100 : 0;
    const apr = totalStaked > 0 ? (annualRewardAmount * 100) / totalStaked : 0;
    console.log(totalStaked, 'Total staked');

    setMyAPR(Number(apr).toFixed(2));
  }

  async function getTotalRewardsPaid() {
    const totalRewards = await poolContract.totalRewardsPaid();
    setTotalRewardsPaid(ethers.utils.formatUnits(totalRewards.toString()));
  }

  async function myRewards() {
    const earned = await poolContract.earned(address);
    setRewardEarned(ethers.utils.formatUnits(earned.toString()));
  }

  async function myStakedAmount() {
    const myStake = await poolContract.balanceOf(address);
    setMyStake(ethers.utils.formatUnits(myStake.toString()));
  }

  async function balance() {
    const balance = await tokenContract.balanceOf(address);
    console.log("Balance of address %s is %s", address, balance);
    setTokenBalance(ethers.utils.formatUnits(balance));
  }

  async function checkAllowance() {
    console.log("Checking allowance");
    const allowance = await tokenContract.allowance(address, poolAddress[chainId]);
    console.log("Pool contract allowance is ", allowance);
    setHasApproval(allowance > 0);
  }

  async function totalSupply() {
    const totalSupply = await poolContract.totalSupply();
    setTotalStaked(ethers.utils.formatUnits(totalSupply));
  }

  async function approve() {
    setPendingApproval(true);
    setDisabled(true);

    console.log(tokenContract);
    try {
      const tx = await tokenContract.connect(provider.getSigner()).approve(poolAddress[chainId], MAX_APPROVAL);
      const data = await tx.wait();
      await checkAllowance();
    } catch (e) {
      console.log(e);
    }
    setPendingApproval(false);
    setDisabled(false);
  }

  function updateStakeAmount(e) {
    let amount = e.target.value * 1;
    if (amount > tokenBalance) {
      e.target.value = tokenBalance;
      amount = tokenBalance;
    }
    setAmountToStake(amount);
  }

  async function stake() {
    setPendingStake(true);
    setDisabled(true);
    try {
      const amount = ethers.utils.parseUnits(amountToStake.toString());
      const tx = await poolContract.connect(provider.getSigner()).stake(amount);
      const data = await tx.wait();
      await setup();
      setToggleState(!toggleState);
    } catch (e) {
      console.log(e);
    }
    setPendingStake(false);
    setDisabled(false);
  }

  function updateWithdrawAmount(e) {
    let amount = e.target.value * 1;
    if (amount > myStake) {
      e.target.value = myStake;
      amount = myStake;
    }
    setAmountToWithdraw(amount);
  }

  async function withdraw() {
    setPendingWithdraw(true);
    setDisabled(true);
    try {
      const amount = ethers.utils.parseUnits(amountToWithdraw.toString());
      const tx = await poolContract.connect(provider.getSigner()).withdraw(amount);
      const data = await tx.wait();
      await setup();
      setToggleState(!toggleState);
    } catch (e) {
      console.log(e);
    }
    setPendingWithdraw(false);
    setDisabled(false);
  }

  async function getRewards() {
    setPendingRewardClaim(true);
    setDisabled(true);
    try {
      const tx = await poolContract.connect(provider.getSigner()).getReward();
      const data = await tx.wait();
      await setup();
      setToggleState(!toggleState);
    } catch (e) {
      console.log(e);
    }
    setPendingRewardClaim(false);
    setDisabled(false);
  }


  return (
    <Container className="mt-4" key={toggleState}>
      <Row>
        <Col>
          <Card className="information-message">
            <CardBody >
            <h3>This staking pool is now closed</h3>
            <p>"Claim" your rewards to withdraw to your wallet then "Withdraw" your stake to remove your stake from the pool.</p>
            <p>If you have any questions about claiming or withdrawing then please ask for help in the <a target="_blank" href="https://t.me/CryptoCartCC">Telegram Group</a></p>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="2">
          &nbsp;
        </Col>
        <Col lg="9">

          <Row className="ccs-page-title mb-3 mb-md-4">
            <h5>CRYPTOCART BSC LP STAKING</h5>
            <h1>Staking</h1>
          </Row>

          <Card className="staking-app">

            <CardHeader className="ccs-connect-wallet text-center col-9 col-lg-6 align-self-end">
              {chainId !== 0 ?
                <Button>Connected {address && address.length > 0 ? address.substring(0, 5) + '...' + address.substring(address.length - 6, address.length - 1) : ''}</Button>
                :
                <Button className="connect-wallet-btn" onClick={connectWallet}>Connect wallet</Button>
              }
            </CardHeader>
            <CardBody className="ccs-staking-inner">
              <Row className="pt-3">
                <Col md="6">
                  <Card className="flex-row ccs-staking-row">
                    <CardHeader className="col-6 col-lg-4" tag="h5">APR</CardHeader>
                    <CardBody className="col-6 col-lg-5" tag="h3">{myAPR}%</CardBody>
                    <div className="col-lg-3"></div>
                  </Card>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col md="12">
                  <Card className="flex-row ccs-staking-row">
                    <CardHeader className="text-uppercase col-6 col-lg-4" tag="h5">Total pool stake</CardHeader>
                    <CardBody className="col-6 col-lg-5" tag="h3">{totalStaked}</CardBody>
                    <div className="col-lg-3"></div>
                  </Card>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col md="12">
                  <Card className="flex-row ccs-staking-row">
                    <CardHeader className="text-uppercase col-6 col-lg-4" tag="h5">My balance</CardHeader>
                    <CardBody className="col-6 col-lg-5" tag="h3">{tokenBalance}</CardBody>
                    <div className="col-lg-3"></div>
                  </Card>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col md="12">
                  <Card className="flex-row ccs-staking-row">
                    <CardHeader className="text-uppercase col-6 col-lg-4" tag="h5">My stake</CardHeader>
                    <CardBody className="col-6 col-lg-5" tag="h3">{myStake}</CardBody>
                    <div className="col-lg-3"></div>
                  </Card>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col className="break">

                </Col>
                <Col md="12">
                  <Card className="flex-row ccs-staking-row">
                    <CardHeader className="text-uppercase col-6 col-lg-4" tag="h5">My rewards</CardHeader>
                    <CardBody className="col-6 col-lg-5" tag="h3">
                      {rewardEarned}
                    </CardBody>
                    <div className="col-12 col-lg-3">
                      {rewardEarned > 0 ?
                        <CardFooter className="ccs-action flex-column pt-3 pt-lg-0">
                          <Button color="primary" onClick={getRewards} disabled={pendingRewardClaim || disabled} style={{ width: "100%" }}>
                            {pendingRewardClaim ?
                              <Spinner size="sm" color="dark">{''}</Spinner>
                              :
                              "Claim"
                            }
                          </Button>
                        </CardFooter>
                        :
                        ""
                      }
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col md="12">
                  <Card className="flex-row ccs-staking-row">
                    <CardHeader className="text-uppercase col-6 col-lg-4" tag="h5">Total rewards paid</CardHeader>
                    <CardBody className="col-6 col-lg-5" tag="h3">{totalRewardsPaid}</CardBody>
                    <div className="col-lg-3"></div>
                  </Card>
                </Col>
              </Row>
              {!hasApproval ?
                <Row className="pt-3 pt-md-4 switch-network">
                  <Col md="12">
                    {isChainSupported ?
                      <Button onClick={approve} style={{ width: "100%" }} disabled={pendingApproval || !isChainSupported}>
                        {pendingApproval ?
                          <Spinner size="sm" color="dark">{''}</Spinner>
                          :
                          "Approve"
                        }
                      </Button>
                      :
                      "Please switch to Binance network"
                    }
                  </Col>
                </Row>
                :
                <>
                  <Row className="pt-3">
                    <Card className="flex-row ccs-staking-row">
                      <Col xs="12" md="8" className="enter-amount">
                        <Input onChange={updateStakeAmount} placeholder="Enter amount to stake" />
                      </Col>
                      <Col xs="12" md="4" className="ccs-action stake-withdraw pt-3 pt-md-0">
                        <Button color="primary" onClick={stake} disabled={pendingStake || disabled} style={{ width: "100%" }}>
                          {pendingStake ?
                            <Spinner size="sm" color="dark">{''}</Spinner>
                            :
                            "Stake"
                          }
                        </Button>
                      </Col>
                    </Card>
                  </Row>
                  <Row className="pt-3">
                    <Card className="flex-row ccs-staking-row">
                      <Col xs="12" md="8" className="enter-amount">
                        <Input onChange={updateWithdrawAmount} placeholder="Enter amount to withdraw" />
                      </Col>
                      <Col xs="12" md="4" className="ccs-action stake-withdraw pt-3 pt-md-0">
                        <Button color="primary" onClick={withdraw} disabled={pendingWithdraw || disabled} style={{ width: "100%" }}>
                          {pendingWithdraw ?
                            <Spinner size="sm" color="dark">{''}</Spinner>
                            :
                            "Withdraw"
                          }
                        </Button>
                      </Col>
                    </Card>
                  </Row>
                </>
              }
            </CardBody>
          </Card>
        </Col>
        <Col lg="1">
          &nbsp;
        </Col>
      </Row>
    </Container>
  );
}

export default Staking;